<template>
  <div class="healthList">
    <div
      class="itemContent"
      v-for="(item, index) in health"
      :key="index"
      @click="checkDetail(item)"
    >
      <div class="item flex2">
        <div class="itemLeft flex3">
          <div class="title one">
            {{ item.title }}
          </div>
          <div class="info three">{{ item.title }}</div>
          <div class="bottom flex1">
            <div class="read_num">{{ item.read_num }}阅读量</div>
            <div class="date">{{ item.create_time }}</div>
          </div>
        </div>
        <div class="itemRight">
          <img :src="item.img" class="pexelsImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    health: {
      type: Array
    }
  },
  data () {
    return {}
  },
  methods: {
    checkDetail (val) {
      this.$emit('checkDetail', val)
    }
  }
}
</script>

<style lang="less" scoped>
.healthList {
  .itemContent {
    background-color: #fff;
    padding: 10px;
    box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
    border-radius: 10px;
    margin-bottom: 15px;
    .item {
      display: flex;
      justify-content: space-between;
      .itemLeft {
        flex: 1;
        color: #333333;
        font-size: 12px;
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .bottom {
          color: #808080;
        }
      }
      .itemRight {
        padding-left: 27px;
        width: 82px;
        height: 82px;
        .pexelsImg {
          width: 82px;
          height: 82px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }
  //
  .three {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .flex1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex2 {
    display: flex;
  }
  .flex3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
