<template>
  <div class="healthAdvice">
    <!-- <vaeSearch v-model="search_word" @vaeSearch="vaeSearch" placeholder='搜索健康文章' ></vaeSearch> -->
    <div class="search">
      <van-search
        v-model="search_word"
        show-action
        shape="round"
        placeholder="搜索健康文章"
      >
        <template #action>
          <div @click="search" class="seachStyle">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="productLoad"
      >
        <vaeHealth :health="healthList" @checkDetail="checkDetail"></vaeHealth>
      </van-list>
      
    </div>
  </div>
</template>

<script>
// import vaeSearch from '@/components/search.vue'
import vaeHealth from '@/components/healthList.vue'
import { topicList, topicMore, topicDetail } from '@/api/studentProduct'
export default {
  components: {
    // vaeSearch,
    vaeHealth
  },
  data () {
    return {
      search_word: '',
      healthList: [],
      loading: false,
      finished: false,
      countAll: null,
      listQuery: {
        page: 1, // 页数
        limit: 10
      },
      listObj: {
        type: 2,
        page: 1,
        search_word: ''
      }
    }
  },
  created () {
    this.getTopicList()
  },
  watch: {
    search_word: {
      handler (val) {
        if (val == '') {
          this.getTopicList()
        }
      }
    }
  },
  methods: {
    async getTopicList () {
      const res = await topicMore({ page: 1, search_word: '' })
      if (res.data.code == 200) {
        // this.healthList = res.data.data.list

        if(this.healthList) {
          this.healthList = []
        }
        res.data.data.list.forEach(element => {
          this.healthList.push(element)
        })
        if (this.healthList.length >= res.data.data.count) {
          this.finished = true
        }
        this.countAll = res.data.data.count
      }
    },
    async search (val) {
      const res = await topicMore({ page: 1, search_word: this.search_word })
      if (res.data.code == 200) {
        this.healthList = res.data.data.list
      }
    },
    // 分页
    productLoad () {
      if (this.healthList.length >= this.countAll) {
        this.finished = true
      }
      this.listObj.page++
      this.loading = false
      this.getTopicList()
    },
    async checkDetail (val) {
      this.$router.push({
        path: '/healthDetail',
        query: {
          id: val.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.healthAdvice {
  background: #f7f8f9;
  .content {
    padding: 15px;
  }
  ::v-deep .seachStyle {
    font-size: 13px;
    padding: 0 10px;
    border-radius: 18px;
    color: #fff !important;
    background-color: #2163e9 !important;
  }
  ::v-deep .van-search {
    background: none;
  }
  ::v-deep .van-search__content--round {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
